/* MESSENGER
------------------------- */

.featurette-transparent {
    border-top: 1px solid transparent;
}

.messenger {
    text-align: center;
    /*background: linear-gradient(45deg, #83B874, yellow);*/
    background: linear-gradient(-45deg, #7C478B, #83B874);
}

.messenger h2 {
    font-size: 32px;
    margin-bottom: 32px;
    /*color: #2D2D2D;*/
    color: #fff;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.messenger .col-centered {
    @media(max-width: 991px) {
        margin-top: 120px;
    }
    margin-bottom: 120px;
}

.row.messenger {
    margin-right: auto;
    margin-left: auto;
}
