/* HERO
-------------------------------------------------- */

.hero {
    color: #fff;
    background-image: url(../img/hero.jpg);
    background-repeat: no-repeat;
    padding-bottom: 283px;
    padding-top: 283px;
    //margin-bottom: 80px;
    //background-size: contain;
    background-position: center;
    //background-position: left;
    @media(min-width: 1848px) {
        background-size: 100% auto;
    }
}

/* Hero small */
.hero-small {
    color: #fff;
    background: linear-gradient(45deg, #7C478B, #ae7cbc);
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 133px;
    padding-top: 133px;
    //margin-bottom: 80px;
    h1 {
        font-size: 4rem;
    }
}

/* Hero about */
.hero-about {
    color: #fff;
    background: linear-gradient(-45deg, #7C478B, #ae7cbc);
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 283px;
    padding-top: 283px;
    margin-bottom: 0;
    //margin-bottom: 80px;
    h1 {
        font-size: 4rem;
    }

}

.hero-about:nth-of-type(2) {
    color: $gray-darker;
    background: #fff;
}

.hero-about:nth-of-type(3) {
    background: linear-gradient(45deg, #83B874, #abcfa1);
}
