/* FOOTER
-------------------------------------------------- */

footer {
    color: #fff;
    background: #3a3a3a;
    padding-top: 64px;
    padding-bottom: 64px;
}

footer div.row {
    margin-bottom: 32px;
}

.social li {
    margin-right: 8px;
}

footer p {
    margin: 6px 0 6px 0;
}

footer h4 {
    margin-bottom: 6px;
}

.copyright {
    font-family: Georgia;
}

footer .fa-bolt {
    color: #83B874;
}

footer a,
footer a:hover,
footer a:focus,
footer a:active {
    color: #d3d3d3;
    text-decoration: none;
    padding: 2px 0 !important;
    background-color: #3a3a3a !important;
    display: inline-block !important;
}

footer a:hover {
    //text-decoration: underline !important;
    color: #fff;
}

footer .col-md-3 {
    @media(max-width:991px) {
        margin-top: 20px;
    }
}

/*.back-to-top {
height: 49px;
background: #7C478B;
}

.back-to-top:hover {
background: #fff;
}

.back-to-top p,
.back-to-top p:hover,
.back-to-top p:focus,
.back-to-top p:active {
color: #fff;
text-decoration: none;
}*/

.back-to-top {
    border-radius: 0;
    font-size: 14px;
    &:focus {
        background: #683c75;
    }
    &:hover {
        background: #4a2b53;
    }
}
