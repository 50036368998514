/*!
 * BlitzRock.com v0.1.1 (https://www.blitzrock.com)
 * Copyright 2017 BlitzRock.com
 */

// Global
// @see http://stackoverflow.com/questions/25189749/bootstrap-grunt-watch-wont-create-bootstrap-min-css
@import "global/global.scss";
@import "global/responsive.scss";
@import "global/fixes.scss";

// Partials
@import "partials/nav.scss";
//@import "partials/carousel.scss";
@import "partials/hero.scss";
@import "partials/features.scss";
@import "partials/price-table.scss";
//@import "partials/marketing.scss";
@import "partials/messenger.scss";
@import "partials/featurettes.scss";
@import "partials/footer.scss";

// Templates
@import "templates/apps.scss";
@import "templates/privacy.scss";
