/* GLOBAL STYLES
-------------------------------------------------- */

/* Padding below the footer and lighter body text */
/*body {
color: #5a5a5a;
}*/

//== Colors
//
//## Bootstrap variables converted from less to sass.

//$gray-base:              #000;
$gray-base:              #070707; // darken #2D2D2D 6.5%
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

//$brand-primary:         darken(#428bca, 6.5%); // #337ab7
$brand-primary:         darken(#7C478B, 6.5%);
//$brand-success:         #5cb85c;
$brand-success:         #83B874;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

//

.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover {
    color: #7C478B;
}

.featurette-divider {
    margin: 40px 0;
    @media(min-width:992px) {
        margin: 96px 0; /* Space out the Bootstrap <hr> more */
    }
}

.main {
    padding-bottom: 32px;
    @media(min-width:992px){
        padding-top: 64px;
        padding-bottom: 96px;
    }
}

.main-legal {
    padding-bottom: 96px;
}

.margin-bottom {
    margin-bottom: 40px;
    @media(min-width:992px) {
        margin-bottom: 20px;
    }
}

.margin-bottom-sm {
    margin-bottom: 40px;
    @media(min-width:992px) {
        margin-bottom: 0;
    }
}

.margin-bottom-82 {
    margin-bottom: 62px;
    @media(min-width:992px) {
        margin-bottom: 82px;
    }
}
