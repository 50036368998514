/* NAVIGATION BAR
-------------------------------------------------- */

.navbar {
    border-radius: 0;
    //margin-top: 20px;
}

.affix-top {
    margin-top: 20px;
}

.navbar-brand {
    padding: 13px 15px;
}

#navbar {
    padding: 0;
}

// @link http://stackoverflow.com/questions/34573447/how-do-i-remove-this-white-line-from-my-bootstrap-navbar-and-change-the-text-col
.navbar-inverse {
    .navbar-toggle {
        border: none;
        //outline: none;
        &:hover {
            background-color: transparent;
        }
        &:focus {
            background-color: transparent;
        }
    }
    .navbar-collapse {
        border: none;
        box-shadow: none;
    }
}

.navbar-primary {
    .icon-bar {
        background: $gray-dark;
    }
}

#nav.affix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background: transparent;
}

/* Navbar fix */
@media(max-width:767px) {
    .navbar-nav {
        margin: 0 0;
    }
}
