/* Featurettes
------------------------- */

/* Thin out the marketing headings */
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1px;
}

.featurette img {
    @media(max-width: 991px) {
        margin-bottom: 30px;
        padding-top: 10px;
    }
}

.messenger img {
    @media(max-width: 991px) {
        margin-bottom: 0;
        padding-top: 0;
    }
}
