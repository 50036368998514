/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
    /* Navbar positioning foo */
    .navbar-wrapper {
        margin-top: 20px;
    }
    .navbar-wrapper .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .navbar-wrapper .navbar {
        padding-right: 0;
        padding-left: 0;
    }

    /* The navbar becomes detached from the top, so we round the corners */
    .navbar-wrapper .navbar {
        border-radius: 4px;
    }

    /* Bump up size of carousel content */
    .carousel-caption p {
        margin-bottom: 20px;
        font-size: 21px;
        line-height: 1.4;
    }

    .featurette-heading {
        font-size: 50px;
    }
}

@media (min-width: 992px) {
    .featurette-heading {
        margin-top: 120px;
    }
}
