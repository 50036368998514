.app-clip {
    @media(min-width: 992px) {
        position: absolute;
        clip: rect(0px, 195px, 64px, 0px);
    }
}

.spacer {
    @media(min-width: 992px) {
        width: 100%;
        height: 69px;
        //height: 1px;
        //background:red;
        //display: inline-block;
        //float: left;
    }
}

.crop {
    //margin:.5em 10px .5em 0;
    //overflow: hidden;
    img {
        //margin:-20px -15px -40px -55px;
    }
}
